<template>
  <div class="arti-bottom">
    <span class="inactive">No active auction</span>
  </div>
</template>

<script>
export default {
  name: "NonAuctionFooter",
};
</script>
