<template>
  <router-link
    :to="
      nftLink(
        tokenBlockChainId || tokenId,
        currency,
        getContractAddressByCurrency(currency, tokenBlockChainId)
      )
    "
    class="arti-bottom"
    :class="{ disabled: disableControl }"
  >
    <span class="first">{{ formatNumber(minBidPrice) }} {{ currency }}</span>
    <span class="second">
      <span class="usd" v-if="minBidPrice && currentUsdtPrice"
        >{{ formatNumber(currentUsdtPrice, true) }} USD</span
      >
      <Countdown
        :end-time="endTime"
        :start-time="startTime"
        @finish="needRefresh"
        v-if="!needRefreshCountdown"
      />
    </span>
  </router-link>
</template>

<script>
import formatNumberMixin from "@/mixins/formatNumberMixin";
import Countdown from "@/components/common/Countdown";
import getContractAddressByCurrencyMixin from "@/mixins/getContractAddressByCurrencyMixin";
import refreshCountdownMixin from "@/mixins/refreshCountdownMixin";
import useLinkMixin from "@/mixins/useLinkMixin";

export default {
  name: "AuctionFooter",
  mixins: [
    formatNumberMixin,
    getContractAddressByCurrencyMixin,
    refreshCountdownMixin,
    useLinkMixin,
  ],
  props: {
    currentUsdtPrice: {
      required: true,
    },
    disableControl: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    minBidPrice: {
      required: true,
    },
    tokenBlockChainId: {
      required: true,
    },
    tokenId: {
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    endTime: {
      type: Number,
      required: true,
    },
    startTime: {
      type: Number,
      required: true,
    },
  },
  components: {
    Countdown,
  },
};
</script>

<style scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
