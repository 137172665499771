<template>
  <span v-if="auctionIsNotStarted" class="vac-wrapper not-started">
    <span class="vac-label"
      >{{ startWithCapitalizeLetter ? "Starts" : "starts" }} in</span
    >
    <vac
      class="vac"
      :end-time="Number(`${startTime}000`)"
      ref="vac"
      :speed="1000"
      @finish="finishCountdown"
    >
      <template v-slot:process="{ timeObj }">
        <span class="timer" v-if="isShort">
          <span v-if="timeObj.d !== '0'">{{ timeObj.d }}d</span>
          <span class="hours" v-if="timeObj.h !== '0'">{{ timeObj.h }}h</span>
          <span class="minutes" v-if="timeObj.m !== '0'">{{ timeObj.m }}m</span>
          <span class="seconds">{{ timeObj.s }}s</span>
        </span>
        <span class="timer" v-else>
          <span v-if="timeObj.d !== '0'">
            <span>{{ timeObj.d }}</span>
            <span>days</span>
          </span>
          <span v-if="timeObj.h !== '0'">
            <span>{{ timeObj.h }}</span>
            <span>hours</span>
          </span>
          <span>
            <span v-if="timeObj.m !== '0'">{{ timeObj.m }}</span>
            <span>minutes</span>
          </span>
          <span>
            <span>{{ timeObj.s }}</span>
            <span>seconds</span>
          </span>
        </span>
      </template>
      <template v-slot:finish>
        <span>Ended</span>
      </template>
    </vac>
  </span>
  <span v-else class="vac-wrapper is-started">
    <span class="vac-label" v-if="!auctionEnded">{{ startWithCapitalizeLetter ? "Ends" : "ends" }} in</span>
    <vac
      class="vac"
      :end-time="Number(`${endTime}000`)"
      ref="vac1"
      :speed="1000"
    >
      <template v-slot:process="{ timeObj }">
        <span class="timer" v-if="isShort">
          <span v-if="timeObj.d !== '0'">{{ timeObj.d }}d</span>
          <span class="hours" v-if="timeObj.h !== '0'">{{ timeObj.h }}h</span>
          <span class="minutes" v-if="timeObj.m !== '0'">{{ timeObj.m }}m</span>
          <span class="seconds">{{ timeObj.s }}s</span>
        </span>
        <span class="timer" v-else>
          <span v-if="timeObj.d !== '0'">
            <span>{{ timeObj.d }}</span>
            <span>days</span>
          </span>
          <span v-if="timeObj.h !== '0'">
            <span>{{ timeObj.h }}</span>
            <span>hours</span>
          </span>
          <span>
            <span v-if="timeObj.m !== '0'">{{ timeObj.m }}</span>
            <span>minutes</span>
          </span>
          <span>
            <span>{{ timeObj.s }}</span>
            <span>seconds</span>
          </span>
        </span>
      </template>
      <template v-slot:finish>
        <span>Auction ended</span>
      </template>
    </vac>
  </span>
</template>

<script>
export default {
  name: "Countdown",
  props: {
    startWithCapitalizeLetter: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    startTime: {
      type: Number,
      required: true,
    },
    endTime: {
      type: Number,
      required: true,
    },
    isShort: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  computed: {
    auctionIsNotStarted() {
      return !!(
        this.startTime && this.startTime > Math.floor(Date.now() / 1000)
      );
    },
    auctionEnded() {
      return this.endTime < Math.floor(Date.now() / 1000);
    },
  },
  methods: {
    finishCountdown() {
      this.$emit("finish");
    },
  },
};
</script>
