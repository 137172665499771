<template>
  <UsernameLink
    :wrapClass="'arti-top'"
    :user-id="ownerId"
    :username="ownerNickName"
    :user-address="ownerAddress"
  />
</template>

<script>
import UsernameLink from "@/components/common/UsernameLink";
export default {
  name: "TokenCardHeader",
  components: { UsernameLink },
  props: {
    ownerNickName: {
      required: true,
    },
    ownerId: {
      required: true,
    },
    ownerAddress: {
      required: true,
    },
  },
};
</script>
