export default {
  computed: {
    tokenBlockChainId() {
      return this.token?.blockChainId || 0;
    },
    tokenRoyalty() {
      return this.token?.ownerPercent;
    },
    tokenId() {
      return this.token?.id;
    },
    tokenTitle() {
      return this.token?.title || "";
    },
    tokenFileUrl() {
      return this.token?.fileUrl;
    },
    tokenPreviewUrl() {
      return this.token?.previewUrl;
    },
    tokenType() {
      return this.token?.type;
    },
    isFixedPrice() {
      return this.token?.activeAuction?.type === "SIMPLE_AUCTION";
    },
    ownerNickName() {
      return this.token?.owner.nickname || "";
    },
    ownerId() {
      return this.token?.owner.id;
    },
    ownerAddress() {
      return this.token?.owner.address || "";
    },
    creatorNickName() {
      return this.token?.creator?.nickname || "";
    },
    creatorAddress() {
      return this.token?.creator?.address;
    },
    tokenHash() {
      return this.token?.hash;
    },
    creatorId() {
      return this.token?.creator?.id;
    },
    tokenDescription() {
      return this.token?.description;
    },
    isTimeAuction() {
      return this.token?.activeAuction?.type === "TIME_AUCTION";
    },
    minBidPrice() {
      return this.token?.activeAuction?.minBidPrice || 0;
    },
    currentUsdtPrice() {
      return this.token?.activeAuction?.currentUsdtPrice || 0;
    },
    endTime() {
      return this.token?.activeAuction?.endTime || 0;
    },
    startTime() {
      return this.token?.activeAuction?.startTime || 0;
    },
    currency() {
      return this.token?.currency || "";
    },
    tokenCreated() {
      return this.token?.created || 0;
    },
    isSold() {
      return !this.token?.activeAuction;
    },
    auctionId() {
      return this.token?.activeAuction?.auctionId || 0;
    },
    tokenCollections() {
      return this.token?.collection || [];
    },
    tokenTags: {
      get() {
        return this.token?.tags || [];
      },
      set(val) {
        this.token.tags = val;
      },
    },
    tokenCollectionId() {
      return this.token?.collection?.[0].id || 0;
    },
    tokenCollectionTitle() {
      return this.token?.collection?.[0].title || 0;
    },
    tokenCollectionOwnerAddress() {
      return this.token?.collection?.[0].owner.address || "";
    },
    tokenCollectionOwnerId() {
      return this.token?.collection?.[0].owner.id;
    },
    tokenCollectionOwnerNickname() {
      return this.token?.collection?.[0].owner.nickname;
    },
    isGasLessToken() {
      return !this?.tokenBlockChainId;
    },
    tokenMetaPrice() {
      return this.token?.metaPrice || 0;
    },
  },
};
