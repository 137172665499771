<template>
  <router-link
    :to="
      nftLink(
        tokenBlockChainId || tokenId,
        currency,
        getContractAddressByCurrency(currency, tokenBlockChainId)
      )
    "
    class="arti-bottom"
    :class="{ disabled: disableControl }"
  >
    <span class="first">{{ formatNumber(minBidPrice) }} {{ currency }}</span>
    <span class="second" v-if="minBidPrice">
      <span class="usd"
        >{{
          formatNumber(convertToUsdPrice(minBidPrice, currency), true)
        }}
        USD</span
      >
      <span class="arti-label">Fixed price</span>
    </span>
  </router-link>
</template>

<script>
import convertToUsdPriceMixin from "@/mixins/convertToUsdPriceMixin";
import formatNumberMixin from "@/mixins/formatNumberMixin";
import getContractAddressByCurrencyMixin from "@/mixins/getContractAddressByCurrencyMixin";
import useLinkMixin from "@/mixins/useLinkMixin";

export default {
  name: "FixedPriceFooter",
  mixins: [
    formatNumberMixin,
    convertToUsdPriceMixin,
    useLinkMixin,
    getContractAddressByCurrencyMixin,
  ],
  props: {
    minBidPrice: {
      required: true,
    },
    currency: {
      required: true,
    },
    tokenBlockChainId: {
      required: true,
    },
    tokenId: {
      required: true,
    },
    disableControl: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
};
</script>

<style scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
