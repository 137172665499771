import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      getUsdPrices: "price/getUsdPrices",
      getSymbol: "wallet/getSymbol",
    }),
  },
  methods: {
    convertToUsdPrice(price, currency = null) {
      let USD = this.getUsdPrices.length
        ? currency
          ? this.getUsdPrices.find((i) => i.symbol === currency).value
          : this.getUsdPrices.find((i) => i.symbol === this.getSymbol).value
        : 0;
      return price * USD;
    },
  },
};
