<template>
  <div
    class="arti-tile"
    v-if="mode === 'default'"
    style="will-change: transform"
  >
    <TokenCardHeader
      :owner-id="ownerId"
      :owner-nick-name="ownerNickName"
      :owner-address="ownerAddress"
    />
    <TokenCardContent
      :file-url="tokenFileUrl"
      :preview-url="tokenPreviewUrl"
      :title="tokenTitle"
      :token-block-chain-id="tokenBlockChainId"
      :token-id="tokenId"
      :token-type="tokenType"
      :currency="currency"
      :disable-control="disableControl"
      :token-collection="tokenCollectionTitle"
      :token-collection-id="tokenCollectionId"
      :token-collections="tokenCollections"
    />
    <FixedPriceFooter
      v-if="isFixedPrice || (isGasLessToken && !isTimeAuction)"
      :min-bid-price="!isGasLessToken ? minBidPrice : tokenMetaPrice"
      :currency="currency"
      :token-block-chain-id="tokenBlockChainId"
      :token-id="tokenId"
      :disable-control="disableControl"
    />
    <AuctionFooter
      :current-usdt-price="currentUsdtPrice"
      v-if="isTimeAuction"
      :min-bid-price="minBidPrice"
      :end-time="endTime"
      :start-time="startTime"
      :currency="currency"
      :token-id="tokenId"
      :token-block-chain-id="tokenBlockChainId"
      :disable-control="disableControl"
    />
    <NonAuctionFooter v-if="isSold && !isGasLessToken" />
  </div>
  <div class="item" v-else-if="mode === 'edit'">
    <div class="nft-data">
      <TokenContentType
        :file-url="tokenFileUrl"
        :token-type="tokenType"
        :show-audio-player="false"
        :preview-url="tokenPreviewUrl"
      />
      <div class="title">
        <span>{{ tokenTitle }}</span>
      </div>
    </div>
    <div class="nft-actions">
      <div @click="changeTokenCollection">Move</div>
      <div @click="removeTokenFromCollection">Remove</div>
    </div>
  </div>
</template>

<script>
import FixedPriceFooter from "@/components/common/tokenCard/FixedPriceFooter";
import AuctionFooter from "@/components/common/tokenCard/AuctionFooter";
import TokenCardHeader from "@/components/common/tokenCard/TokenCardHeader";
import TokenCardContent from "@/components/common/tokenCard/TokenCardContent";
import tokenInfoMixin from "@/mixins/tokenInfoMixin";
import NonAuctionFooter from "@/components/common/tokenCard/NonAuctionFooter";
import TokenContentType from "@/components/common/TokenContentType";
export default {
  name: "TokenCard",
  mixins: [tokenInfoMixin],
  props: {
    mode: {
      type: String,
      required: false,
      default: () => "default",
    },
    disableControl: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    token: {
      type: Object,
      required: true,
    },
  },
  methods: {
    removeTokenFromCollection() {
      this.$emit("removeTokenFromCollection", this.tokenId);
    },
    changeTokenCollection() {
      this.$emit("changeTokenCollection", this.tokenId);
    },
  },
  components: {
    TokenContentType,
    NonAuctionFooter,
    TokenCardContent,
    TokenCardHeader,
    AuctionFooter,
    FixedPriceFooter,
  },
};
</script>
