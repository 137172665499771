export default {
  data: () => ({
    needRefreshCountdown: false,
  }),
  methods: {
    needRefresh() {
      this.needRefreshCountdown = true;
      setTimeout(() => {
        this.needRefreshCountdown = false;
      }, 0);
    },
  },
};
