<template>
  <div class="arti-middle" :class="{ audio: previewUrl }">
    <div class="preview">
      <TokenContentType
        :token-type="tokenType"
        :file-url="fileUrl"
        :preview-url="previewUrl"
        :show-audio-player="false"
      />
    </div>

    <div class="arti-overlay">
      <div class="arti-title" v-if="title">{{ title }}</div>

      <router-link
        :to="
          nftLink(
            tokenBlockChainId || tokenId,
            currency,
            getContractAddressByCurrency(currency, tokenBlockChainId)
          )
        "
        class="arti-link"
        :class="{ disabled: disableControl }"
      ></router-link>

      <div class="arti-cols" v-if="tokenCollections.length <= 5">
        <span v-for="(item, index) in tokenCollections" :key="index">
          <router-link :to="collectionLink(item.id)" class="arti-col-img">
            <img :src="item.fileUrl" lazy="loaded" />
          </router-link>
          <span class="arti-col-tip">{{ item.title }}</span>
        </span>
      </div>

      <div class="arti-cols" v-else>More than 5 collections</div>
    </div>
  </div>
</template>

<script>
import truncateMixin from "@/mixins/truncateMixin";
import TokenContentType from "@/components/common/TokenContentType";
import getContractAddressByCurrencyMixin from "@/mixins/getContractAddressByCurrencyMixin";
import useLinkMixin from "@/mixins/useLinkMixin";

export default {
  name: "TokenCardContent",
  components: { TokenContentType },
  mixins: [truncateMixin, getContractAddressByCurrencyMixin, useLinkMixin],
  props: {
    tokenCollection: {
      required: true,
    },
    tokenCollectionId: {
      required: true,
    },
    tokenCollections: {
      type: Array,
      required: false,
      default: () => [],
    },
    fileUrl: {
      required: true,
    },
    previewUrl: {
      required: false,
    },
    title: {
      required: true,
    },
    tokenBlockChainId: {
      required: true,
    },
    tokenId: {
      required: true,
    },
    tokenType: {
      type: String,
      required: false,
    },
    currency: {
      type: String,
      required: true,
    },
    disableControl: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
};
</script>

<style scoped>
.disabled {
  pointer-events: none;
}
</style>
